import { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../../common/CustomTable";
import CustomButton from "../../../../common/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  addSponsor,
  getAllSponsors,
  updateSponsor,
} from "../../../../../apis/Sponsor";
import { findMessage, Toaster } from "../../../../../constants";
import SponsorModal from "./SponsorModal";

const Sponsors = () => {
  const [sponsors, setSponsors] = useState(null);

  const [loader, setLoader] = useState(false);

  const [activeModal, setActiveModal] = useState(null);

  const { fundraiserId = null } = useParams();

  const navigate = useNavigate();

  const sponsorTableCols = [
    { name: "First Name", dataIndex: "firstName" },
    { name: "Last Name", dataIndex: "lastName" },
    { name: "Business Name", dataIndex: "businessName" },
    { name: "Website Url", dataIndex: "websiteUrl" },
    { name: "Sponsor Status", dataIndex: "sponsorStatus" },
    { name: "Total Donations", dataIndex: "totalTransactions" },
  ];

  const sponsorTableRows = sponsors
    ? sponsors.map((sponsor) => {
        return {
          ...sponsor,
          sponsorStatus: sponsor.isFeatured ? "Sponsored" : "Not Sponsored",
        };
      })
    : [];

  const fetchSponsors = useCallback(async () => {
    setLoader(true);
    try {
      const res = await getAllSponsors(fundraiserId);
      let sponsorList = res.data.filter(
        (sponsor) => sponsor.type === "business"
      );
      if (sponsorList?.length) sponsorList = sponsorList.reverse();
      setSponsors(sponsorList);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error fetching sponsors!"));
    }
  }, [fundraiserId]);

  const handleAddSponsor = async (data) => {
    setLoader(true);
    try {
      debugger;
      const { businessImage, isFeatured, ...restData } = data;
      const sponsorData = {
        ...restData,
        ...(isFeatured && { fundRaisingId: fundraiserId }),
        image: data?.businessImage,
        type: "business",
      };
      const sponsor = await addSponsor(sponsorData);
      Toaster("success", "Sponsor added successfully!");
      setActiveModal(null);
      setLoader(false);
      setSponsors([...sponsors, sponsor.data]);
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error adding sponsor!"));
    }
  };

  const handleUpdateSponsor = async (data) => {
    setLoader(true);
    try {
      const sponsorId = activeModal.sponsor._id;
      const { businessImage, ...restData } = data;
      const sponsorData = {
        ...restData,
        ...(typeof data?.businessImage === "string"
          ? {}
          : { image: data?.businessImage }),
        type: "business",
        fundRaisingId: fundraiserId,
      };

      await updateSponsor(sponsorId, sponsorData);
      Toaster("success", "Sponsor updated successfully!");
      setActiveModal(null);
      setLoader(false);
      fetchSponsors();
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error updating sponsor!"));
    }
  };

  useEffect(() => {
    fetchSponsors();
  }, [fetchSponsors]);

  const onModalClose = () => setActiveModal(null);

  return (
    <div>
      <SponsorModal
        open={activeModal?.type === "ADD_SPONSOR"}
        onClose={onModalClose}
        loader={loader}
        onSubmit={handleAddSponsor}
      />
      <SponsorModal
        open={activeModal?.type === "UPDATE_SPONSOR"}
        onClose={onModalClose}
        loader={loader}
        onSubmit={handleUpdateSponsor}
        sponsorData={activeModal?.sponsor}
        title={"Update Sponsor"}
        submitText={"Update"}
      />
      <CustomButton
        title={"< Back to fundraiser"}
        onClick={() => navigate("/admin/dashboard/" + fundraiserId)}
        className={"navigate-button"}
      />
      <div
        className="navigate-text"
        onClick={() => navigate("/admin/dashboard/" + fundraiserId)}
      >
        {"< Back to fundraiser"}
      </div>
      <div className="fundraiser-dashboard-wrapper">
        <div className="title">Fundraiser Sponsors</div>
        <div className="sponsor-table-wrapper">
          <CustomTable
            loader={loader}
            rows={sponsorTableRows}
            columns={sponsorTableCols}
            isActions={sponsors?.length}
            onEdit={(data) =>
              setActiveModal({ type: "UPDATE_SPONSOR", sponsor: data })
            }
            isDelete={false}
            maxHeight={500}
            emptyDisplayText="No sponsors in this fundraising"
          />
        </div>
        <CustomButton
          title={"Add Sponsor"}
          className={"add-sponsor-button"}
          disabled={loader}
          onClick={() => setActiveModal({ type: "ADD_SPONSOR" })}
        />
      </div>
    </div>
  );
};

export default Sponsors;
