import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getDonationIntent,
  getFundraiserDataByReferalCode,
} from "../../apis/Auth";
import {
  DASHBOARD_MODALS,
  DONATION_REFERAL_TYPES,
  findMessage,
  PAYMENT_REASONS,
  Toaster,
} from "../../constants";
import Spinner from "../common/Spinner";
import CustomButton from "../common/CustomButton";
import TokenImg from "../../assets/tokenImg.png";
import CustomInput from "../common/CustomInput";
import { Formik, Form } from "formik";
import PaymentModal from "../pages/Stripe/PaymentModal";
import CustomRadioButton from "../common/CustomRadioButton";
import CustomCheckBox from "../common/CustomCheckBox";
import { donationValidationSchema } from "../../constants/validate";
import { addSponsor } from "../../apis/Sponsor";

const Donate = () => {
  const { referalCode = null } = useParams();
  const [donationData, setDonationData] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [loader, setLoader] = useState(false);
  const onModalClose = () => setActiveModal(null);

  const fetchData = useCallback(async () => {
    if (!referalCode) return;
    const body = { referalCode };
    const data = await getFundraiserDataByReferalCode(body);
    let type;
    if (data.message) {
      if (
        data.message.toLowerCase().includes(DONATION_REFERAL_TYPES.FUNDRAISER)
      )
        type = DONATION_REFERAL_TYPES.FUNDRAISER;
      else if (
        data.message.toLowerCase().includes(DONATION_REFERAL_TYPES.GROUP)
      )
        type = DONATION_REFERAL_TYPES.GROUP;
      else if (
        data.message.toLowerCase().includes(DONATION_REFERAL_TYPES.PARTICIPANT)
      )
        type = DONATION_REFERAL_TYPES.PARTICIPANT;
    }
    if (!type) {
      Toaster("error", "Invalid Referal Code!");
      setDonationData({ type: DONATION_REFERAL_TYPES.INVALID });
    }
    setDonationData({ type, data: data.data });
  }, [referalCode]);

  useEffect(() => {
    fetchData();
  }, [referalCode]);

  if (!donationData) return <Spinner />;
  if (donationData.type === DONATION_REFERAL_TYPES.INVALID)
    return <div>Invalid or Expired Referal Code!</div>;

  return (
    <div className="donation-page">
      <PaymentModal
        open={activeModal?.type === DASHBOARD_MODALS.PURCHASE_TOKENS}
        onClose={onModalClose}
        title={"Purchase Tokens"}
        amount={activeModal?.amount}
        reason={PAYMENT_REASONS.DONATION}
        referalCode={referalCode}
        sponsorData={activeModal?.sponsorData}
      />
      <div className="title">DONATE</div>
      {donationData.type === DONATION_REFERAL_TYPES.PARTICIPANT ? (
        <div className="participant-data">
          <div className="description">
            {`You are currently using referal code of the participant: `}
            <strong className="name">{`${donationData.data.firstName} ${donationData.data.lastName}`}</strong>
          </div>
        </div>
      ) : donationData.type === DONATION_REFERAL_TYPES.GROUP ? (
        <div className="participant-data">
          <div className="description">
            {`You are currently donating to the group: `}
            <strong className="name">{`${donationData.data.groupName}`}</strong>
          </div>
        </div>
      ) : (
        <div className="participant-data">
          <div className="description">
            {`You are currently donating to the fundraiser: `}
            <strong className="name">{`${donationData.data.fundRaisingName}`}</strong>
          </div>
        </div>
      )}

      <Formik
        initialValues={{
          amount: 10,
          type: "individual",
          firstName: "",
          lastName: "",
          businessName: "",
          websiteUrl: "",
          hideName: false,
          image: "",
          isDragOver: false,
        }}
        validationSchema={donationValidationSchema}
        onSubmit={async (data) => {
          debugger;
          setLoader(true);

          let { hideName, ...sponsorData } = data;
          let { firstName, lastName, businessName, type } = sponsorData;

          if (type === "individual" && hideName) {
            lastName = sponsorData.lastName.charAt(0).toUpperCase() + ".";
            sponsorData.lastName = lastName;
          }

          sponsorData = { ...sponsorData, referalCode };

          const createdSponsor = await addSponsor(sponsorData);

          sponsorData = {
            firstName,
            lastName,
            businessName,
            type,
            sponsorId: createdSponsor.data._id,
          };

          setActiveModal({
            type: DASHBOARD_MODALS.PURCHASE_TOKENS,
            amount: data.amount,
            sponsorData,
          });
          setLoader(false);
        }}
      >
        {({ values, setValues, setFieldValue, errors }) => {
          // Handle drop event
          const handleDrop = (e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
              setFieldValue("image", file);
            }
            setFieldValue("isDragOver", false);
          };
          // Handle drag over event
          const handleDragOver = (e) => {
            console.log(e);
            setFieldValue("isDragOver", true);
            e.preventDefault();
            e.stopPropagation();
          };

          // Handle drag leave event
          const handleDragLeave = (e) => {
            setFieldValue("isDragOver", false);
            e.preventDefault();
            e.stopPropagation();
          };

          // Handle file input change
          const handleFileChange = (e) => {
            const file = e.currentTarget.files[0];
            if (file) {
              setFieldValue("image", file);
            }
          };

          const isBusiness = values.type === "business";
          return (
            <div>
              <div className="border-line" />
              <div className="donation-sponsor-wrapper" id="sponsor-wrapper">
                <div className="sponser-info">
                  <CustomRadioButton
                    title="Individual"
                    name="individual"
                    className={"sponsor-check"}
                    checked={!isBusiness}
                    onClick={() => setValues({ ...values, type: "individual" })}
                  />
                  <div className={!isBusiness ? "" : "blurry"}>
                    <CustomInput
                      label={"First Name"}
                      name={"firstName"}
                      placeholder="Johnson"
                    />
                    <CustomInput
                      label={"Last Name"}
                      name={"lastName"}
                      placeholder="Wade"
                    />
                    <CustomCheckBox
                      title={
                        "Keep anonymous (Only first name and last initial will show on donor list)"
                      }
                      className={"sponsor-check"}
                      checked={values.hideName}
                      onClick={() =>
                        setValues({ ...values, hideName: !values.hideName })
                      }
                    />
                  </div>
                </div>
                <div className="sponser-info">
                  <CustomRadioButton
                    title="Business"
                    name="business"
                    className={"sponsor-check"}
                    checked={isBusiness}
                    onClick={() => setValues({ ...values, type: "business" })}
                  />
                  <div className={values.type === "business" ? "" : "blurry"}>
                    <CustomInput
                      label={"First Name"}
                      name={"firstName"}
                      placeholder="Wade"
                    />
                    <CustomInput
                      label={"Last Name"}
                      name={"lastName"}
                      placeholder="Johnson"
                    />
                    <CustomInput
                      label={"Business Name"}
                      name={"businessName"}
                      placeholder="Wally Plumbing"
                    />
                    <CustomInput
                      label={"Website Url"}
                      name={"websiteUrl"}
                      placeholder={"https://www.topscorefundraising.com"}
                    />
                  </div>
                </div>
                <div
                  className={
                    values.type === "business"
                      ? "sponser-image"
                      : "sponser-image blurry"
                  }
                >
                  <div
                    className={`logo`}
                    onDrop={handleDrop}
                    onDragEnter={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    {values.isDragOver ? (
                      "Upload Logo"
                    ) : values.image ? (
                      <img
                        src={URL.createObjectURL(values.image)}
                        alt="Uploaded Logo"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    ) : (
                      "Drop logo here"
                    )}
                  </div>
                  <input
                    id="fileInput"
                    name="logo"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  {errors.image && (
                    <div className="logo-error">{errors.image}</div>
                  )}
                  <CustomButton
                    title={"Import Logo"}
                    type={"button"}
                    onClick={() => document.getElementById("fileInput").click()}
                  />
                </div>
              </div>
              <div className="border-line" />

              <div className="token-btn-wrapper">
                <div className="token-img-wrapper">
                  <img src={TokenImg} alt="token-img" className="token-img" />
                </div>
                <div className="token-second-wrapper">
                  <div className="token-buttons-wrapper">
                    <div className="token-heading-wrapper">
                      <h2 className="token-heading">SELECT AMOUNT</h2>
                    </div>
                    <div>
                      <Form>
                        <CustomButton
                          type={"button"}
                          title={"10$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 10 })}
                        />
                        <CustomButton
                          type={"button"}
                          title={"15$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 15 })}
                        />
                        <CustomButton
                          type={"button"}
                          title={"25$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 25 })}
                        />
                        <CustomButton
                          type={"button"}
                          title={"50$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 50 })}
                        />
                        <CustomButton
                          type={"button"}
                          title={"75$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 75 })}
                        />
                        <CustomButton
                          type={"button"}
                          title={"100$"}
                          className="token-btn"
                          onClick={() => setValues({ ...values, amount: 100 })}
                        />
                        <div className="input-title">CUSTOM</div>
                        <CustomInput
                          title={"Custom Amount"}
                          className="custom-input"
                          name={"amount"}
                          type={"number"}
                          min={"10"}
                        />
                        <div className="input-footer">
                          Increment of 5, minimum $5
                        </div>
                        <CustomButton
                          title={"Donate Now"}
                          className="donate-btn"
                          disabled={loader}
                          onClick={() => {
                            if (Object.keys(errors).length > 0) {
                              const element =
                                document.getElementById("sponsor-wrapper");
                              if (element) {
                                element.scrollIntoView({ behavior: "smooth" });
                              }
                            }
                          }}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <div className="border-line" />
    </div>
  );
};

export default Donate;
