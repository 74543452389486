import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../common/CustomButton";
import CustomTable from "../../../../common/CustomTable";
import {
  addManualFundsToParticipant,
  approveParticipant,
  generateParticipantInvitationLink,
  getDashboard,
  getPendingApprovalParticipants,
  inviteAdmin,
} from "../../../../../apis/Admin";
import {
  DASHBOARD_MODALS,
  dateToLocalTimestamp,
  findMessage,
  FUNDRAISER_STATUS,
  Toaster,
} from "../../../../../constants/index";
import GoalModal from "../NewFundraiserModalV2/GoalModal";
import CauseModal from "../NewFundraiserModalV2/CauseModal";
import copy from "copy-to-clipboard";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CreateGroups,
  deleteFundraiser,
  updateFundraiser,
  updateFundraisingStripe,
} from "../../../../../apis/Fundraiser";
import {
  assignParticipantToGroup,
  deleteGroup,
  updateGroup,
} from "../../../../../apis/Group";
import InviteParticipantModal from "../ParticipantModals/InviteParticipantModal";
import LinkModal from "../ParticipantModals/LinkModal";
import FundraiserInfoModal from "../NewFundraiserModalV2/FundraiserInfoModal";
import ConfirmationModal from "../../../../common/ConfirmationModal";
import AssignParticipantModal from "../ParticipantModals/AssignParticipantModal";
import PermissionModal from "../PermissionModal";
import { Delete, Edit } from "@mui/icons-material";
import GroupNameModal from "../GroupNameModal";
import NewGroupModal from "../NewGroupModal";
import LinkStripeModal from "../LinkStripeModal";
import { getAllAccounts } from "../../../../../apis/Stripe";
import GroupsModal from "../NewFundraiserModalV2/GroupsModal";
import UploadFileModal from "../../../../common/UploadFileModal";
import InviteAdminModal from "../InviteAdminModal";
import GameModal from "../GameModal";
import { createGame, deleteGame, updateGame } from "../../../../../apis/Game";
import ManualDonationModal from "../ParticipantModals/ManualDonationModal";
import { useSelector } from "react-redux";
import { getAllSponsors } from "../../../../../apis/Sponsor";
import ExistingGameModal from "../ExistingGameModal";

const Info = ({
  type,
  value,
  changeClickAction = null,
  changeButtonText = "Change",
  valueClass = "",
  typeClass = "",
  parentClass = "",
}) => (
  <div className={"info-entity-wrapper " + parentClass}>
    <div className="info-entity">
      <div className={"info-type " + typeClass}>{type}:</div>
      <div className={"info-value " + valueClass}>{value}</div>
    </div>
    {changeClickAction && (
      <div className="change-button" onClick={changeClickAction}>
        {changeButtonText}
      </div>
    )}
  </div>
);

const Group = ({
  groupData,
  index,
  handleGroupGoalChange,
  handleGroupCauseChange,
  setActiveModal,
  disableLinkStripe,
  disableActions,
}) => {
  const [selectedParticipant, selectParticipant] = useState(null);

  const auth = useSelector((state) => state.auth.me);

  const isEditEnabled =
    auth?.role === "admin" || auth?.email === groupData.admin.email;

  groupData.participants = groupData.participants.map((participant) => {
    return {
      ...participant,
      name: participant.firstName + " " + participant.lastName,
      totalDonations: participant.totalDonations || "0",
    };
  });
  const groupName = groupData?.groupName;
  const groupAdmin =
    groupData?.admin?.firstName + " " + groupData?.admin?.lastName;
  const groupTableCols = [
    { name: "Name", dataIndex: "name" },
    { name: "Phone", dataIndex: "phoneNo" },
    { name: "Email", dataIndex: "email" },
    { name: "Donations", dataIndex: "totalDonations", prefix: "$" },
  ];
  const groupTableRows = groupData?.participants;

  return (
    <div className="group">
      <div className="heading">
        <div className="type">Group: </div>
        {groupName}
        {isEditEnabled && !disableActions && (
          <div
            className="edit-button"
            onClick={() =>
              setActiveModal({ type: DASHBOARD_MODALS.GROUP_NAME, index })
            }
          >
            <Edit />
          </div>
        )}
        {isEditEnabled && !disableActions && (
          <div
            className="delete-button"
            onClick={() =>
              setActiveModal({ type: DASHBOARD_MODALS.DELETE_GROUP, index })
            }
          >
            <Delete />
          </div>
        )}
      </div>
      <div className="heading">
        <span className="type">Admin: </span>
        {groupAdmin}
      </div>
      <div className="table">
        <CustomTable
          rows={groupTableRows}
          columns={groupTableCols}
          emptyDisplayText={"Group currently has no participants"}
          isSelection={isEditEnabled}
          onSelect={selectParticipant}
        />
      </div>
      <div className="button-box-group">
        {isEditEnabled && (
          <div className="button-box">
            <div className="small-button-box-group">
              <CustomButton
                className={"custom-button"}
                title={"Send Text"}
                disabled={selectedParticipant === null}
              />
              <CustomButton
                className={"custom-button"}
                title={"Send Email"}
                disabled={selectedParticipant === null}
              />
            </div>
            <CustomButton
              className={"custom-button"}
              title={"Add Unassigned Participant"}
              onClick={() =>
                setActiveModal({
                  type: DASHBOARD_MODALS.ASSIGN_PARTICIPANT,
                  index,
                })
              }
            />
            {/* <CustomButton
            className={"custom-button"}
            title={"Invite New Participant"}
          /> */}
          </div>
        )}
        <div className="button-box">
          {isEditEnabled && (
            <CustomButton
              className={
                "custom-button" +
                (!groupData.stripe ? " red-button" : " green-button")
              }
              title={
                !groupData.stripe
                  ? "Link Stripe Account"
                  : "Update Stripe Account"
              }
              onClick={() => {
                if (disableLinkStripe)
                  return Toaster("warning", "Wait for data to load");
                setActiveModal({
                  type: DASHBOARD_MODALS.GROUP_STRIPE_MODAL,
                  index,
                });
              }}
            />
          )}
          {!groupData.stripe && isEditEnabled && (
            <div className="para">
              A Strip account must be linked before the start of the fundraiser
            </div>
          )}
        </div>
        <div className="button-box">
          {isEditEnabled && (
            <CustomButton
              className={"custom-button"}
              title={"Add Funds"}
              onClick={() => {
                setActiveModal({
                  type: DASHBOARD_MODALS.MANUAL_DONATION,
                  participant: groupData.participants[selectedParticipant],
                  groupId: groupData._id,
                });
              }}
              disabled={selectedParticipant === null}
            />
          )}
          {isEditEnabled && (
            <div className="para">
              Add donations to a participants account manually
            </div>
          )}
        </div>
      </div>
      <div className={isEditEnabled ? "group-info" : "group-info-horizontal"}>
        <Info
          type={"Total Donations"}
          value={
            "$" +
            (groupData?.groupTotalDonations === null
              ? "---"
              : groupData.groupTotalDonations)
          }
        />
        <Info
          type={"Goal"}
          value={groupData?.groupTarget ? "$" + groupData?.groupTarget : "---"}
          changeClickAction={
            isEditEnabled &&
            (() => {
              handleGroupGoalChange(index);
            })
          }
        />

        <Info
          type={"Cause"}
          value={groupData?.groupCause || "---"}
          changeClickAction={
            isEditEnabled &&
            (() => {
              handleGroupCauseChange(index);
            })
          }
        />
      </div>
      <div className="donation-link-wrapper">
        <Info
          type={"Donation Link"}
          value={
            groupData?.groupDonationLink
              ? process.env.REACT_APP_BASE_URL +
                "donate/" +
                groupData?.groupDonationLink
              : "---"
          }
          changeButtonText="copy"
          changeClickAction={() => {
            copy(
              process.env.REACT_APP_BASE_URL +
                "donate/" +
                groupData?.groupDonationLink
            );
            Toaster("success", "Copied to clipboard!");
          }}
          valueClass="small-text"
          parentClass="full-max-width"
        />
      </div>
    </div>
  );
};

const FundraiserAdminDashboard = () => {
  const { fundraiserId = null } = useParams();

  const [fundraiserData, setFundraiserData] = useState(null);

  const [activeModal, setActiveModal] = useState(null);

  const [loader, setLoader] = useState(false);

  const [selectedAdmin, selectAdmin] = useState(null);

  const [selectedGame, selectGame] = useState(null);

  const [selectedParticipant, selectParticipant] = useState(null);

  const [stripeAccounts, setStripeAccounts] = useState(null);

  const [participantRequests, setParticipantReqeusts] = useState(null);

  const navigate = useNavigate();

  const fundraiserInfo = fundraiserData?.fundRaiser;
  const adminInfo = fundraiserData?.admin;
  const hasParticipants = true;
  const hasGroups = !!fundraiserData?.group?.length;
  const groups = fundraiserData?.group;
  const games = fundraiserData?.games;

  let totalFunding = fundraiserInfo?.totalFunding || 0;
  if (groups)
    groups.forEach(
      (group) => (totalFunding += group?.groupTotalDonations || 0)
    );

  const getFundraisingStatus = () => {
    if (!fundraiserInfo) return null;

    try {
      const currentTime = Date.now();
      const fundraiserStartTime = Number(fundraiserInfo?.fundRaisingStartTime);
      const fundraiserEndTime = fundraiserInfo?.fundRaisingEndTime;

      if (currentTime < fundraiserStartTime)
        return FUNDRAISER_STATUS.NOT_STARTED;
      if (currentTime > fundraiserEndTime) return FUNDRAISER_STATUS.ENDED;

      let isValid = true;
      if (groups.length) {
        groups.forEach((group) => {
          if (!group.stripe) isValid = false;
        });
      } else {
        if (!fundraiserInfo.fundRaisingStripe) isValid = false;
      }

      if (isValid) return FUNDRAISER_STATUS.STARTED;

      return FUNDRAISER_STATUS.STARTED_BUT_INACTIVE;
    } catch {
      return FUNDRAISER_STATUS.ERROR;
    }
  };

  const fundraisingStatus = getFundraisingStatus();

  const unassignedParticipants = useMemo(
    () => {
      if (!fundraiserData) return [];
      if (!hasGroups) return fundraiserInfo.participant;
      const fundraiserParticipants = fundraiserInfo.participant;
      const groupParticipants = groups
        .map((group) => group.participants || false)
        .filter(Boolean)
        .flat();
      const groupParticipantsSet = new Set(
        groupParticipants.map((participant) => participant.email)
      );
      return fundraiserParticipants.filter(
        (participant) => !groupParticipantsSet.has(participant.email)
      );
    },
    //eslint-disable-next-line
    [fundraiserData]
  );

  const fetchFundraiserData = useCallback(async () => {
    if (!fundraiserId) return;
    setFundraiserData(await getDashboard(fundraiserId));
  }, [fundraiserId]);

  const fetchStripeAccounts = useCallback(async () => {
    let accounts = await getAllAccounts();
    accounts = accounts.filter((account) => account.isVerified);
    setStripeAccounts(accounts);
  }, []);

  // const fetchSponsors = useCallback(async () => {
  //   if (!fundraiserId) return;
  //   const res = await getAllSponsors(fundraiserId);
  //   let sponsorList = res.data.filter((sponsor) => sponsor.type === "business");
  //   if (sponsorList?.length) sponsorList = sponsorList.reverse();
  //   setSponsors(sponsorList);
  // }, [fundraiserId]);

  // useEffect(() => {
  //   fetchSponsors();
  // }, [fundraiserId]);

  useEffect(() => {
    fetchFundraiserData();
    if (fundraiserId)
      getPendingApprovalParticipants(fundraiserId).then((data) => {
        setParticipantReqeusts(data);
      });
    //eslint-disable-next-line
  }, [fundraiserId]);

  useEffect(() => {
    fetchStripeAccounts();
    //eslint-disable-next-line
  }, []);

  const adminsTableCols = [
    { name: "Name", dataIndex: "firstName" },
    { name: "Phone", dataIndex: "phoneNo" },
    { name: "Email", dataIndex: "email" },
  ];
  const adminsTableRows = fundraiserInfo?.admin || [];

  const participantsTableCols = [
    { name: "Name", dataIndex: "name" },
    { name: "Phone", dataIndex: "phone" },
    { name: "Email", dataIndex: "email" },
    { name: "Donations", dataIndex: "donations", prefix: "$" },
  ];

  const participantRequestsTableCols = [
    { name: "Name", dataIndex: "name" },
    { name: "Phone", dataIndex: "phoneNo" },
    { name: "Email", dataIndex: "email" },
  ];

  const participantsTableRows = unassignedParticipants.map((participant) => {
    return {
      name: participant.firstName + " " + participant.lastName,
      phone: participant.phoneNo,
      email: participant.email,
      donations: participant.totalDonations || 0,
    };
  });

  const participantRequestsTableRows = participantRequests?.map((request) => {
    return {
      requestId: request._id,
      name: request.participant.firstName + " " + request.participant.lastName,
      ...request.participant,
    };
  });

  const gameTableCols = [
    { name: "Name", dataIndex: "gameName" },
    { name: "Description", dataIndex: "gameDescription" },
  ];

  const gameTableRows = fundraiserData?.games || [];

  const newGroupGoalCapacity = useMemo(() => {
    try {
      return fundraiserData?.fundRaiser?.fundRaisingGoal
        ? fundraiserData.fundRaiser.fundRaisingGoal -
            fundraiserData.group.reduce(
              (acc, val) => acc + (Number(val.groupTarget) || 0),
              0
            )
        : 0;
    } catch {
      return 0;
    }
  }, [fundraiserData]);

  const onModalClose = () => {
    setActiveModal(null);
  };

  const makeCopy = (arr) => {
    if (!arr) return [];
    return arr.map((x) => {
      return { ...x };
    });
  };

  const updateFundraiserInfo = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const body = {
          fundRaisingName: data.name,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          fundRaisingTaxId: data.taxId,
          fundRaisingRoutingNumber: data.routingNumber,
          fundRaisingAccountNumber: data.accountNumber,
          fundRaisingStartTime: dateToLocalTimestamp(data.startDate),
          fundRaisingEndTime: dateToLocalTimestamp(data.endDate),
          fundRaisingId: fundraiserData.fundRaiser?._id,
        };
        await updateFundraiser(body);
        setFundraiserData({
          ...fundraiserData,
          fundRaiser: { ...fundraiserData.fundRaiser, ...body },
        });
        Toaster("success", "Fundraiser Updated Successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        Toaster("error", e?.message || "Error updating fundraiser");
      }
    },
    [fundraiserData]
  );

  const handleAssignParticipant = useCallback(
    async (participantIndex) => {
      setLoader(true);
      try {
        const groupIndex = activeModal.index;
        const groupId = fundraiserData.group[groupIndex]._id;
        const participantId = unassignedParticipants[participantIndex]._id;

        const body = {
          groupId,
          participantId,
        };
        await assignParticipantToGroup(body);
        setLoader(false);
        setActiveModal(null);
        Toaster("success", "Participant assigned to group successfully!...");
        fetchFundraiserData();
      } catch (e) {
        setLoader(false);
        Toaster(
          "error",
          e?.response?.data?.message ||
            "Error while assigning participant to group!"
        );
      }
    },
    //eslint-disable-next-line
    [fundraiserData, activeModal]
  );

  const handleDeleteFundraiser = useCallback(async () => {
    setLoader(true);
    try {
      await deleteFundraiser(fundraiserData?.fundRaiser?._id);
      setActiveModal(null);
      Toaster("success", "Fundraising deleted successfully! Redirecting...");
      setFundraiserData(null);
      setTimeout(() => {
        navigate("/admin/dashboard");
      }, 3000);
    } catch (e) {
      setLoader(false);
      Toaster(
        "error",
        e?.response?.data?.message ||
          "Failed to delete fundraiser! Try again later..."
      );
    }
    //eslint-disable-next-line
  }, [fundraiserData]);

  const handleDeleteGroup = useCallback(async () => {
    setLoader(true);
    try {
      const groupId = fundraiserData?.group[activeModal.index]._id;
      await deleteGroup(groupId);
      setActiveModal(null);
      Toaster("success", "Group deleted successfully!");
      setLoader(false);
      const updatedGroups = fundraiserData.group.filter(
        (group) => group._id !== groupId
      );
      setFundraiserData({ ...fundraiserData, group: updatedGroups });
    } catch (e) {
      setLoader(false);
      Toaster(
        "error",
        e?.response?.data?.message ||
          "Failed to delete group! Try again later..."
      );
    }
  }, [fundraiserData, activeModal]);

  const updateFundraiserGoal = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const combinedGroupGoal = fundraiserData.group.reduce(
          (accumulator, currentGroup) =>
            accumulator + Number(currentGroup.groupTarget || 0),
          0
        );
        if (combinedGroupGoal > data.fundraiserGoal) {
          Toaster(
            "warning",
            "Fundraiser goal must be at least sum of group goals!"
          );
          setLoader(false);
          return;
        }
        const body = {
          fundRaisingGoal: data.fundraiserGoal,
          fundRaisingId: fundraiserData.fundRaiser?._id,
        };
        await updateFundraiser(body);
        Toaster("success", "Fundraiser Goal updated successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = { ...fundraiserData };
        updatedFundraiserData.fundRaiser.fundRaisingGoal = data.fundraiserGoal;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error updating fundraiser goal!");
        setLoader(false);
      }
    },
    [fundraiserData]
  );

  const updateGroupGoal = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const index = activeModal.index;
        const body = {
          groupId: data.groups[index]._id,
          groupTarget: data.groups[index].groupTarget,
        };
        await updateGroup(body);
        Toaster("success", "Group Goal updated successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = { ...fundraiserData };
        updatedFundraiserData.group[index].groupTarget =
          data.groups[index].groupTarget;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error updating fundraiser goal!");
        setLoader(false);
      }
    },
    [fundraiserData, activeModal]
  );

  const updateFundraiserCause = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const body = {
          fundRaisingCause: data.fundRaisingCause,
          fundRaisingId: fundraiserData.fundRaiser?._id,
        };
        await updateFundraiser(body);
        Toaster("success", "Fundraiser Cause updated successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = fundraiserData;
        updatedFundraiserData.fundRaiser.fundRaisingCause =
          data.fundRaisingCause;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error updating fundraiser goal!");
        setLoader(false);
      }
    },
    [fundraiserData]
  );

  const inviteParticipant = useCallback(
    async (data) => {
      setLoader(true);
      try {
        let body = {};
        if (data?.group) body = { ...data, groupId: data.group };
        else body = { ...data, fundRaisingId: fundraiserData.fundRaiser?._id };
        const invitationCode = await generateParticipantInvitationLink(body);
        const link =
          process.env.REACT_APP_BASE_URL +
          "participant/signup/" +
          invitationCode;
        setActiveModal({
          type: DASHBOARD_MODALS.LINK,
          link,
          title: "Participant Invitation Link",
          info: "Use this link below to invite the participant into fundraiser",
        });
        setLoader(false);
      } catch {
        setLoader(false);
        Toaster("error", "Error while generating invitation link!");
      }
    },
    [fundraiserData]
  );

  const handleInviteAdmin = useCallback(
    async (data) => {
      setLoader(true);
      try {
        let body = {};
        body = {
          ...data,
          phoneNo: data?.phoneNumber,
          fundRaiserId: fundraiserData.fundRaiser?._id,
        };
        const invitationCode = await inviteAdmin(body);
        const link =
          process.env.REACT_APP_BASE_URL + "admin/signup/" + invitationCode;
        setActiveModal({
          type: DASHBOARD_MODALS.LINK,
          link,
          title: "Admin Invitation Link",
          info: "Use this link below to invite the admin into the fundraiser",
        });
        setLoader(false);
      } catch {
        setLoader(false);
        Toaster("error", "Error while generating invitation link!");
      }
    },
    [fundraiserData]
  );

  const updateGroupCause = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const index = activeModal.index;
        const body = {
          groupId: data.groups[index]._id,
          groupCause: data.groups[index].groupCause,
        };
        await updateGroup(body);
        Toaster("success", "Group Cause updated successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = { ...fundraiserData };
        updatedFundraiserData.group[index].groupCause =
          data.groups[index].groupCause;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error updating group cause!");
        setLoader(false);
      }
    },
    [fundraiserData, activeModal]
  );

  const handleEditPermission = (data) => {
    console.log(data);
  };

  const updateGroupName = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const index = activeModal.index;

        const body = {
          groupId: fundraiserData.group[index]._id,
          groupName: data.groupName,
        };

        await updateGroup(body);

        Toaster("success", "Group Name updated successfully!");
        setActiveModal(null);
        setLoader(false);

        let updatedFundraiserData = fundraiserData;
        updatedFundraiserData.group[index].groupName = data.groupName;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error updating fundraiser name!");
        setLoader(false);
      }
    },
    [fundraiserData, activeModal]
  );

  const updateGroupStripe = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const index = activeModal.index;

        const body = {
          groupId: fundraiserData.group[index]._id,
          stripe: data.stripeAccountId,
        };
        await updateGroup(body);
        Toaster("success", "Stripe Linked successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = fundraiserData;
        updatedFundraiserData.group[index].stripe = data.stripeAccountId;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", "Error linking stripe to the group!");
        setLoader(false);
      }
    },
    [fundraiserData, activeModal]
  );

  const updateFundraiserStripe = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const body = {
          fundRaisingStripe: data.stripeAccountId,
          fundRaisingId: fundraiserData.fundRaiser?._id,
        };
        await updateFundraisingStripe(body);
        Toaster("success", "Fundraiser Stripe updated successfully!");
        setActiveModal(null);
        setLoader(false);
        let updatedFundraiserData = { ...fundraiserData };
        updatedFundraiserData.fundRaiser.fundRaisingStripe =
          data.stripeAccountId;
        setFundraiserData(updatedFundraiserData);
      } catch (e) {
        Toaster("error", e?.message || "Error updating fundraiser stripe!");
        setLoader(false);
      }
    },
    [fundraiserData]
  );

  const handleCreateNewGroup = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const fundRaisingId = fundraiserData?.fundRaiser?._id;
        const body = { groups: [{ ...data.newGroupData, fundRaisingId }] };
        const groupsCreated = await CreateGroups(body);
        if (data.admin) groupsCreated[0].admin = data.admin;

        const updatedFundraiserData = { ...fundraiserData };
        updatedFundraiserData.group.push(groupsCreated[0]);
        setFundraiserData(updatedFundraiserData);
        Toaster("success", "Group created successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        Toaster(
          "error",
          e?.response?.data?.message ||
            "Failed to create group! Try again later..."
        );
      }
    },
    [fundraiserData]
  );

  const handleCreateNewGroups = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const fundRaisingId = fundraiserData?.fundRaiser?._id;
        const groups = data.map((group) => {
          return {
            groupName: group.name,
            adminEmail: group.admin,
            groupGoal: 0,
            groupCause: "",
            fundRaisingId,
          };
        });
        const body = { groups };
        await CreateGroups(body);
        fetchFundraiserData();
        Toaster("success", "Groups created successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        Toaster(
          "error",
          e?.response?.data?.message ||
            "Failed to create groups! Try again later..."
        );
      }
    },
    [fundraiserData]
  );

  const getFundraisingInfo = useCallback(() => {
    return {
      name: fundraiserInfo?.fundRaisingName,
      address: fundraiserInfo?.address,
      city: fundraiserInfo?.city,
      state: fundraiserInfo?.state,
      zipCode: fundraiserInfo?.zip,
      startDate: fundraiserInfo?.fundRaisingStartTime
        ? new Date(parseInt(fundraiserInfo?.fundRaisingStartTime))
            .toISOString()
            .split("T")[0]
        : "",
      endDate: Math.floor(
        Math.abs(
          fundraiserInfo?.fundRaisingEndTime -
            fundraiserInfo?.fundRaisingStartTime
        ) /
          (1000 * 60 * 60 * 24 * 7)
      ),
      routingNumber: fundraiserInfo?.fundRaisingRoutingNumber,
      accountNumber: fundraiserInfo?.fundRaisingAccountNumber,
      verifyAccountNumber: fundraiserInfo?.fundRaisingAccountNumber,
      taxId: fundraiserInfo?.fundRaisingTaxId,
      checks: {
        isAuthorizedAdmin: false,
        isPrivacyPolicy: false,
        isTerms: false,
      },
    };
    //eslint-disable-next-line
  }, [fundraiserData]);

  const copyFundraiserDonationLink = () => {
    if (!fundraiserInfo?.fundRaisingDonationLink) return;
    copy(
      process.env.REACT_APP_BASE_URL +
        "donate/" +
        fundraiserInfo?.fundRaisingDonationLink
    );
    Toaster("success", "Copied to clipboard!");
  };

  const handleUpdateLogo = useCallback(
    async (image) => {
      setLoader(true);
      try {
        const body = { image, fundRaisingId: fundraiserData.fundRaiser?._id };
        await updateFundraiser(body);
        setActiveModal(null);
        Toaster("success", "Logo Updated Successfully!");
        setLoader(false);
      } catch (e) {
        Toaster("error", e?.message || "Error uploading fundraiser logo!");
        setLoader(false);
      }
    },
    [fundraiserData]
  );

  const handleCreateGame = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const game = await createGame({
          ...data,
          fundRaiserId: fundraiserData.fundRaiser?._id,
        });
        const updatedFundraiserData = fundraiserData;
        fundraiserData.games.push(game);
        setFundraiserData(updatedFundraiserData);
        Toaster("success", "Game created successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        console.log(e);
        Toaster("error", findMessage(e, "Error creating new game!"));
      }
    },
    [fundraiserData]
  );

  const handleExistingGame = useCallback((game) => {
    setActiveModal({ type: DASHBOARD_MODALS.NEW_GAME, game: { ...game } });
  }, []);

  const handleUpdateGame = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const body = {
          ...fundraiserData.games[selectedGame],
          ...data,
          gameId: fundraiserData.games[selectedGame]._id,
          fundRaiserId: fundraiserData.fundRaiser?._id,
        };
        const image = body.image;
        if (typeof image === "string") body.image = null;
        const game = await updateGame(body);
        const updatedFundraiserData = fundraiserData;
        fundraiserData.games[selectedGame] = game;
        setFundraiserData(updatedFundraiserData);
        Toaster("success", "Game updated successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        Toaster("error", e?.message || "Error updating game!");
      }
    },
    [fundraiserData, selectedGame]
  );

  const handleDeleteGame = useCallback(async () => {
    setLoader(true);
    try {
      await deleteGame(fundraiserData.games[selectedGame]._id);
      const updatedFundraiserData = { ...fundraiserData };
      updatedFundraiserData.games.splice(selectedGame, 1);
      setFundraiserData(updatedFundraiserData);
      Toaster("success", "Game deleted successfully!");
      setLoader(false);
      setActiveModal(null);
    } catch (e) {
      setLoader(false);
      Toaster("error", e?.message || "Error deleting selected game!");
    }
  }, [fundraiserData, selectedGame]);

  const handleParticipantApprove = async (value) => {
    try {
      setLoader(true);
      await approveParticipant(value.requestId);
      fetchFundraiserData();
      const updatedParticipantRequests = participantRequests.filter(
        (request) => request._id !== value.requestId
      );
      setParticipantReqeusts(updatedParticipantRequests);
      Toaster("success", "Participant has been approved successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      let errorMessage =
        error.response?.data?.message || "Particiapnt could not be approved";
      Toaster("error", errorMessage);
    }
  };

  const handleManualDonation = async (value) => {
    setLoader(true);

    try {
      const body = {
        amount: Number(value?.amount),
        comments: value?.comments,
        participantId: activeModal.participant._id,
        ...(activeModal.groupId && { groupId: activeModal.groupId }),
        ...(activeModal.fundRaiserId && {
          fundRaiserId: activeModal.fundRaiserId,
        }),
      };

      await addManualFundsToParticipant(body);
      fetchFundraiserData();
      setActiveModal(null);
      setLoader(false);
      Toaster("success", "Funds added successfully!");
    } catch (error) {
      setLoader(false);
      let errorMessage = findMessage(
        error,
        "Unexpected error while adding manual funds!"
      );
      Toaster("error", errorMessage);
    }
  };

  console.log(fundraiserData);

  return (
    <div>
      <CustomButton
        title={"< Back to dashboard"}
        onClick={() => navigate("/admin/dashboard")}
        className={"navigate-button"}
      />
      <div
        className="navigate-text"
        onClick={() => navigate("/admin/dashboard")}
      >
        {"< Back to dashboard"}
      </div>

      <div className="fundraiser-dashboard-wrapper">
        <div className="modals">
          <GoalModal
            fundraiserData={{
              fundraiserGoal: fundraiserInfo?.fundRaisingGoal || 0,
            }}
            submitText={"Update"}
            onSkip={onModalClose}
            skipText={"Cancel"}
            onClose={onModalClose}
            open={activeModal?.type === DASHBOARD_MODALS.FUNDRAISER_GOAL}
            title={"Update Fundraiser Goal"}
            loader={loader}
            updateFundraiserData={updateFundraiserGoal}
          />
          <GoalModal
            fundraiserData={{
              groups: makeCopy(groups),
              fundraiserGoal: fundraiserInfo?.fundRaisingGoal || 0,
            }}
            open={activeModal?.type === DASHBOARD_MODALS.GROUP_GOAL}
            groupAccessIndex={activeModal?.index}
            submitText={"Update"}
            onClose={onModalClose}
            onSkip={onModalClose}
            loader={loader}
            skipText={"Cancel"}
            title={"Update Group Goal"}
            updateFundraiserData={updateGroupGoal}
          />
          <CauseModal
            fundraiserData={{
              fundRaisingCause: fundraiserInfo?.fundRaisingCause || "",
            }}
            submitText={"Update"}
            onSkip={onModalClose}
            skipText={"Cancel"}
            onClose={onModalClose}
            open={activeModal?.type === DASHBOARD_MODALS.FUNDRAISER_CAUSE}
            title={"Update Fundraiser Cause"}
            loader={loader}
            updateFundraiserData={updateFundraiserCause}
          />
          <CauseModal
            fundraiserData={{
              groups: makeCopy(groups),
              fundRaisingCause: fundraiserInfo?.fundRaisingCause || "",
            }}
            open={activeModal?.type === DASHBOARD_MODALS.GROUP_CAUSE}
            groupAccessIndex={activeModal?.index}
            submitText={"Update"}
            onClose={onModalClose}
            onSkip={onModalClose}
            loader={loader}
            skipText={"Cancel"}
            title={"Update Group Cause"}
            updateFundraiserData={updateGroupCause}
          />
          <GroupNameModal
            groupData={groups?.[activeModal?.index]}
            onSubmit={updateGroupName}
            submitText="Update"
            loader={loader}
            open={activeModal?.type === DASHBOARD_MODALS.GROUP_NAME}
            onClose={onModalClose}
            title="Update Group Name"
          />
          <InviteParticipantModal
            loader={loader}
            open={activeModal?.type === DASHBOARD_MODALS.INVITE_PARTICIPANT}
            onClose={onModalClose}
            onSubmit={inviteParticipant}
            groups={groups}
          />
          <InviteAdminModal
            loader={loader}
            open={activeModal?.type === DASHBOARD_MODALS.INVITE_ADMIN}
            onClose={onModalClose}
            onSubmit={handleInviteAdmin}
          />
          <LinkModal
            open={activeModal?.type === DASHBOARD_MODALS.LINK}
            onClose={onModalClose}
            invitationLink={activeModal?.link}
            title={activeModal?.title}
            info={activeModal?.info}
          />
          <AssignParticipantModal
            open={activeModal?.type === DASHBOARD_MODALS.ASSIGN_PARTICIPANT}
            onClose={onModalClose}
            loader={loader}
            participants={participantsTableRows}
            onAssign={handleAssignParticipant}
          />
          <FundraiserInfoModal
            fundraiserData={getFundraisingInfo()}
            title={"Update Fundraiser"}
            loader={loader}
            updateFundraiserData={updateFundraiserInfo}
            open={activeModal?.type === DASHBOARD_MODALS.EDIT_FUNDRAISER}
            onClose={onModalClose}
          />
          <ConfirmationModal
            title={"Confirm Delete Fundraiser"}
            prompt={
              "Are you sure you want to delete the fundraiser? This action can not be undone!"
            }
            open={activeModal?.type === DASHBOARD_MODALS.DELETE_FUNDRAISER}
            onClose={onModalClose}
            onConfirmation={handleDeleteFundraiser}
            warningOnConfirm
            loader={loader}
          />
          <ConfirmationModal
            title={"Confirm Delete Group"}
            prompt={
              "Are you sure you want to delete this group? This action can not be undone!"
            }
            open={activeModal?.type === DASHBOARD_MODALS.DELETE_GROUP}
            onClose={onModalClose}
            onConfirmation={handleDeleteGroup}
            warningOnConfirm
            loader={loader}
          />
          <PermissionModal
            open={activeModal?.type === DASHBOARD_MODALS.PERMISSION_MODAL}
            onClose={onModalClose}
            onConfirmation={(data) => handleEditPermission(data)}
            warningOnConfirm
            selectedData={selectedAdmin?.data}
            loader={loader}
            title={"Edit Permissions"}
          />
          <GroupsModal
            open={activeModal?.type === DASHBOARD_MODALS.NEW_GROUPS}
            onClose={onModalClose}
            loader={loader}
            onSubmit={handleCreateNewGroups}
            title={"Create Groups"}
            hideSkip
          ></GroupsModal>
          <NewGroupModal
            open={activeModal?.type === DASHBOARD_MODALS.NEW_GROUP}
            onClose={onModalClose}
            loader={loader}
            title="Add new group"
            onSubmit={handleCreateNewGroup}
            groupGoalCapacity={newGroupGoalCapacity}
          />
          <LinkStripeModal
            open={activeModal?.type === DASHBOARD_MODALS.GROUP_STRIPE_MODAL}
            onClose={onModalClose}
            loader={loader}
            stripeAccounts={stripeAccounts}
            selectedStripeAccount={
              activeModal?.index && groups[activeModal.index].stripe
            }
            onSubmit={(data) => updateGroupStripe(data)}
            title="Link Stripe Account"
            submitText="Link"
          />
          <LinkStripeModal
            open={
              activeModal?.type === DASHBOARD_MODALS.FUNDRAISER_STRIPE_MODAL
            }
            onClose={onModalClose}
            loader={loader}
            stripeAccounts={stripeAccounts}
            onSubmit={(data) => updateFundraiserStripe(data)}
            title="Link Stripe Account"
            submitText="Link"
          />
          <UploadFileModal
            title="Fundraiser Logo"
            prompt="Upload Fundraiser Logo"
            open={activeModal?.type === DASHBOARD_MODALS.UPLOAD_FILE}
            onClose={onModalClose}
            loader={loader}
            onFileUpload={handleUpdateLogo}
          />
          <GameModal
            open={activeModal?.type === DASHBOARD_MODALS.NEW_GAME}
            onClose={onModalClose}
            loader={loader}
            gameData={activeModal?.game}
            onSubmit={handleCreateGame}
            onExistingGameInfo={() =>
              setActiveModal({ type: DASHBOARD_MODALS.EXISTING_GAME })
            }
          />
          <GameModal
            open={activeModal?.type === DASHBOARD_MODALS.UPDATE_GAME}
            onClose={onModalClose}
            loader={loader}
            onSubmit={handleUpdateGame}
            gameData={fundraiserData?.games?.[selectedGame]}
            submitText={"Update Game"}
          />
          <ExistingGameModal
            open={activeModal?.type === DASHBOARD_MODALS.EXISTING_GAME}
            onClose={onModalClose}
            onSubmit={handleExistingGame}
            fundraisingId={fundraiserId}
          />
          <ConfirmationModal
            title={"Confirm Delete Game"}
            prompt={
              "Are you sure you want to remove this game from the fundraiser?"
            }
            open={activeModal?.type === DASHBOARD_MODALS.DELETE_GAME}
            onClose={onModalClose}
            onConfirmation={handleDeleteGame}
            warningOnConfirm
            loader={loader}
          />
          <ManualDonationModal
            open={activeModal?.type === DASHBOARD_MODALS.MANUAL_DONATION}
            onClose={onModalClose}
            loader={loader}
            onSubmit={handleManualDonation}
          />
        </div>
        <div className="title">Administrator Dashboard</div>
        <div className="sections">
          <div className="information-section section">
            <div className="section-title">Information</div>
            <div className="info-group">
              <Info
                type={"Name"}
                value={
                  (adminInfo?.firstName || "---") +
                  " " +
                  (adminInfo?.lastName || "---")
                }
              />
              <Info
                type={"Fundraiser"}
                value={fundraiserInfo?.fundRaisingName || "---"}
                changeClickAction={() => {
                  setActiveModal({ type: DASHBOARD_MODALS.UPLOAD_FILE });
                }}
                changeButtonText={
                  fundraiserInfo?.fundRaisingImage ? "Update Logo" : "Add Logo"
                }
              />
              <Info
                type={"Fundraiser ID"}
                value={fundraiserInfo?._id || "---"}
              />
            </div>
            <div className="button-group">
              <div
                className="edit-button"
                onClick={() => {
                  if (
                    fundraisingStatus === FUNDRAISER_STATUS.STARTED ||
                    fundraisingStatus === FUNDRAISER_STATUS.STARTED_BUT_INACTIVE
                  )
                    Toaster(
                      "warning",
                      "Editing is disabled during active time of fundraiser!"
                    );
                  else
                    setActiveModal({ type: DASHBOARD_MODALS.EDIT_FUNDRAISER });
                }}
              >
                Edit
              </div>
              <div
                className="delete-button"
                onClick={() => {
                  if (!fundraiserData) {
                    Toaster("warning", "Wait for fundraiser to load!");
                    return;
                  }
                  const currentTime = Date.now();
                  if (
                    currentTime > fundraiserInfo?.fundRaisingStartTime &&
                    currentTime < fundraiserInfo?.fundRaisingStartTime
                  )
                    Toaster(
                      "warning",
                      "Deletion is disabled during active time of fundraiser!"
                    );
                  else
                    setActiveModal({
                      type: DASHBOARD_MODALS.DELETE_FUNDRAISER,
                    });
                }}
              >
                Delete
              </div>
            </div>
            <div className="para">
              Editing and deleting fundraiser may Only be done by primary admin.
              Editing and deleting fundraiser cannot be done after the start
              date of the fundraiser
            </div>
          </div>
          <div className="participants-and-goals-section section">
            <div className="section-title">Participants & Goals</div>
            <div className="info-group">
              <Info
                type={"Total Participants"}
                value={
                  fundraiserInfo?.participant?.length ||
                  (fundraiserInfo?.participant?.length === 0 ? 0 : "---")
                }
              />
              <Info type={"Total Donations"} value={"$" + totalFunding} />
              <Info type={"Amount to Organization"} value={""} />
              <Info
                type={"Goal"}
                value={
                  "$" + fundraiserInfo?.fundRaisingGoal ||
                  (fundraiserInfo?.fundRaisingGoal === 0 ? 0 : "---")
                }
                changeClickAction={() => {
                  if (!fundraiserData)
                    Toaster("warning", "Wait for fundraiser to load!");
                  else
                    setActiveModal({ type: DASHBOARD_MODALS.FUNDRAISER_GOAL });
                }}
              />
              <Info
                type={"Cause"}
                value={fundraiserInfo?.fundRaisingCause || "---"}
                changeClickAction={() => {
                  if (!fundraiserData)
                    Toaster("warning", "Wait for fundraiser to load!");
                  else
                    setActiveModal({ type: DASHBOARD_MODALS.FUNDRAISER_CAUSE });
                }}
              />
              {(!groups || groups.length === 0) && (
                <Info
                  type={"Referal Link"}
                  value={
                    fundraiserInfo?.fundRaisingDonationLink
                      ? process.env.REACT_APP_BASE_URL +
                        "donate/" +
                        fundraiserInfo?.fundRaisingDonationLink
                      : "---"
                  }
                  changeButtonText={"Copy"}
                  changeClickAction={
                    fundraiserInfo?.fundRaisingDonationLink &&
                    (() => {
                      copyFundraiserDonationLink();
                    })
                  }
                  valueClass="small-text"
                />
              )}
            </div>
          </div>
          {/* <div className="bonus-tier-section section">
          <div className="section-title">Bonus Tier</div>
          <div className="info-group">
            <Info type={"School Bonus Tier"} value={""} />
            <Info type={"1st Place Payout"} value={""} />
            <Info type={"2nd Place Payout"} value={""} />
            <Info type={"3rd Place Payout"} value={""} />
          </div>
        </div> */}
        </div>
        <div className="title2">Fundraiser Status</div>
        {!fundraisingStatus ? (
          <div className="para">Loading...</div>
        ) : fundraisingStatus === FUNDRAISER_STATUS.NOT_STARTED ? (
          <div className="para">Fundraiser has not started yet</div>
        ) : fundraisingStatus === FUNDRAISER_STATUS.STARTED ? (
          <div className="para">Fundraiser is in progress</div>
        ) : fundraisingStatus === FUNDRAISER_STATUS.ENDED ? (
          <div className="para">Fundraiser has ended</div>
        ) : fundraisingStatus === FUNDRAISER_STATUS.STARTED_BUT_INACTIVE ? (
          <div className="para">
            Fundraiser has started but is inactive (partially or completely) due
            to unlinked stripe account(s). Kindly resolve the issue to avoid any
            payment blockages.
          </div>
        ) : (
          <div className="para">⚠️Error fetching fundraiser status!</div>
        )}
        {!hasGroups && (
          <div className="button-box-group">
            <div className="button-box">
              <CustomButton
                className={
                  "custom-button" +
                  (!fundraiserInfo?.fundRaisingStripe
                    ? " red-button"
                    : " green-button")
                }
                disabled={!fundraiserData}
                title={
                  !fundraiserInfo?.fundRaisingStripe
                    ? "Link Stripe Account"
                    : "Update Stripe Account"
                }
                onClick={() => {
                  if (!stripeAccounts)
                    return Toaster("warning", "Wait for data to load");
                  setActiveModal({
                    type: DASHBOARD_MODALS.FUNDRAISER_STRIPE_MODAL,
                  });
                }}
              />
              <div className="para">
                If you are creating groups that use separate bank accounts it is
                recommended that you set up stripe accounts after creating the
                groups.
              </div>
            </div>
            <div className="button-box">
              <CustomButton
                className="custom-button"
                title={"Create Groups"}
                disabled={!fundraiserData}
                onClick={() => {
                  setActiveModal({ type: DASHBOARD_MODALS.NEW_GROUPS });
                }}
              />
              <div className="para">
                Create groups to compete against each other
              </div>
            </div>
          </div>
        )}
        <div className="administrators">
          <div className="title">Administrators</div>
          <div className="table">
            <CustomTable
              rows={adminsTableRows}
              columns={adminsTableCols}
              isSelection
              onSelect={(index) =>
                selectAdmin({ index, data: fundraiserInfo?.admin?.[index] })
              }
              maxHeight={300}
            />
          </div>
          <div className="wide">
            <div className="button-box-group-left">
              <CustomButton
                className={"custom-button"}
                title={"Invite New Admin"}
                disabled={!fundraiserData}
                onClick={() =>
                  setActiveModal({
                    type: DASHBOARD_MODALS.INVITE_ADMIN,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="participants">
          <div className="title">Participants</div>
          {participantRequests && (
            <div className="heading">Pending Requests</div>
          )}
          {participantRequests && (
            <div className="table" style={{ marginBottom: "30px" }}>
              <CustomTable
                rows={participantRequestsTableRows}
                columns={participantRequestsTableCols}
                emptyDisplayText={"No pending requests!"}
                maxHeight={400}
                loader={loader}
                isApprovals
                onApprove={handleParticipantApprove}
              />
            </div>
          )}

          <div className="heading">
            {hasGroups ? "Unassigned participants" : "Participants"}
          </div>
          {hasParticipants ? (
            <div>
              <div className="table">
                <CustomTable
                  rows={participantsTableRows}
                  columns={participantsTableCols}
                  emptyDisplayText={"Fundraiser has no unassigned participants"}
                  maxHeight={400}
                  isSelection={!hasGroups}
                  onSelect={selectParticipant}
                />
              </div>
              {hasGroups ? (
                <div className="button-box-group-left">
                  <CustomButton
                    className={"custom-button"}
                    title={"Invite New Participant"}
                    onClick={() =>
                      setActiveModal({
                        type: DASHBOARD_MODALS.INVITE_PARTICIPANT,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="button-box-group">
                  <div className="button-box">
                    <div className="small-button-box-group">
                      <CustomButton
                        className={"custom-button"}
                        title={"Send Text"}
                      />
                      <CustomButton
                        className={"custom-button"}
                        title={"Send Email"}
                      />
                    </div>
                    <CustomButton
                      className={"custom-button"}
                      title={"Invite New Participant"}
                      onClick={() =>
                        setActiveModal({
                          type: DASHBOARD_MODALS.INVITE_PARTICIPANT,
                        })
                      }
                    />
                  </div>
                  <div className="button-box">
                    <CustomButton
                      className={"custom-button"}
                      title={"Add Funds"}
                      onClick={() => {
                        setActiveModal({
                          type: DASHBOARD_MODALS.MANUAL_DONATION,
                          participant:
                            unassignedParticipants[selectedParticipant],
                          fundRaiserId: fundraiserData?.fundRaiser?._id,
                        });
                      }}
                      disabled={selectedParticipant === null}
                    />
                    <div className="para">
                      Add donations to a participants account manually
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <CustomButton
              title={"Add Participants"}
              className={"custom-button"}
              onClick={() => {}}
            />
          )}
        </div>
        {hasGroups && (
          <div className="groups">
            <div className="title">Fundraiser Groups</div>
            {groups.map((group, index) => (
              <Group
                groupData={group}
                handleGroupGoalChange={(index) => {
                  setActiveModal({ type: DASHBOARD_MODALS.GROUP_GOAL, index });
                }}
                handleGroupCauseChange={(index) => {
                  setActiveModal({ type: DASHBOARD_MODALS.GROUP_CAUSE, index });
                }}
                index={index}
                setActiveModal={setActiveModal}
                disableLinkStripe={!stripeAccounts}
                disableActions={
                  fundraisingStatus !== FUNDRAISER_STATUS.NOT_STARTED
                }
              />
            ))}
          </div>
        )}
        {groups && groups.length > 0 && (
          <CustomButton
            title={"Add New Group"}
            className={"custom-button"}
            onClick={() => setActiveModal({ type: DASHBOARD_MODALS.NEW_GROUP })}
          />
        )}
        <div className="games">
          <div className="title">Fundraiser Games</div>
          {!games?.length && (
            <div className="info">
              There are currently no games added to this fundraiser
            </div>
          )}
          {games?.length && (
            <React.Fragment>
              <div className="table">
                <CustomTable
                  rows={gameTableRows}
                  columns={gameTableCols}
                  isSelection
                  onSelect={selectGame}
                />
              </div>
              <div className="games-buttons">
                <CustomButton
                  title="Update"
                  className={"custom-button"}
                  disabled={selectedGame === null}
                  onClick={() =>
                    setActiveModal({ type: DASHBOARD_MODALS.UPDATE_GAME })
                  }
                ></CustomButton>
                <CustomButton
                  title="Delete"
                  className={"custom-button"}
                  disabled={selectedGame === null}
                  onClick={() =>
                    setActiveModal({ type: DASHBOARD_MODALS.DELETE_GAME })
                  }
                ></CustomButton>
              </div>
            </React.Fragment>
          )}
          <div className="flex-child">
            <CustomButton
              title={"Add New Game"}
              disabled={!fundraiserData}
              onClick={() =>
                setActiveModal({ type: DASHBOARD_MODALS.NEW_GAME })
              }
            />
          </div>
        </div>
        <div className="title">Sponsors</div>
        <div className="sponsor-list">
          {(fundraiserData?.sponsors || []).map((sponsor) => (
            <img
              src={sponsor.businessImage}
              alt="logo"
              className="sponsor-image"
            />
          ))}
        </div>
        <CustomButton
          title={"Open sponsors page"}
          onClick={() =>
            navigate("/admin/dashboard/" + fundraiserId + "/sponsors")
          }
        />
      </div>
    </div>
  );
};

export default FundraiserAdminDashboard;
