import React, { useCallback, useEffect, useState } from "react";
import CustomTable from "../../../common/CustomTable";
import CustomButton from "../../../common/CustomButton";
import "./styles.scss";
import {
  createFundraiser,
  deleteFundraiser,
  getAllFundraisers,
  updateFundraiser,
} from "../../../../apis/Fundraiser";
import {
  DASHBOARD_MODALS,
  dateToLocalTimestamp,
  FUNDRAISER_MODALS,
  Toaster,
} from "../../../../constants";
import NewFundraiserModal from "./NewFundraiserModal";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../common/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import NewFundraiserModalV2 from "./NewFundraiserModalV2";
import FundraiserInfoModal from "./NewFundraiserModalV2/FundraiserInfoModal";

const fundraiserColumn = [
  { name: "Fundraiser", dataIndex: "name" },
  { name: "Total Funds", dataIndex: "totalFunds" },
];

const Fundraiser = () => {
  const [loader, setLoader] = useState(false);
  const [fundraisers, setFundraisers] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedFundraiserForAction, setSelectedFundraiserForAction] =
    useState(null);
  const [selectedFundraiser, selectFundraiser] = useState(null);
  const [fundraiserTitle, setFundaiserTitle] = useState("Add New Fundraiser ");

  const auth = useSelector((state) => state.auth.me);

  const navigate = useNavigate();

  const onModalClose = () => setActiveModal({ ...activeModal, type: null });

  const handleOpenFundraiserModal = () =>
    setActiveModal({ type: FUNDRAISER_MODALS.NEW_FUNDRAISER });

  const handleEdit = (value) => {
    setActiveModal({
      type: FUNDRAISER_MODALS.UPDATE_FUNDRAISER,
      fundraiser: value,
    });
    setFundaiserTitle("Edit Fundraiser");
  };

  const updateFundraiserInfo = useCallback(
    async (data) => {
      setLoader(true);
      try {
        const body = {
          fundRaisingName: data.name,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
          fundRaisingTaxId: data.taxId,
          fundRaisingRoutingNumber: data.routingNumber,
          fundRaisingAccountNumber: data.accountNumber,
          fundRaisingStartTime: dateToLocalTimestamp(data.startDate),
          fundRaisingEndTime: dateToLocalTimestamp(data.endDate),
          fundRaisingId: activeModal?.fundraiser?._id,
        };
        await updateFundraiser(body);

        //updating fundraiser data without API call
        let updatedFundrasiers = fundraisers;
        const index = updatedFundrasiers.findIndex(
          (obj) => obj._id === activeModal?.fundraiser?._id
        );
        updatedFundrasiers[index] = { ...updatedFundrasiers[index], ...body };
        setFundraisers(updatedFundrasiers);

        Toaster("success", "Fundraiser Updated Successfully!");
        setLoader(false);
        setActiveModal(null);
      } catch (e) {
        setLoader(false);
        Toaster("error", e?.message || "Error updating fundraiser");
      }
    },
    [activeModal]
  );

  const getFundraisingInfo = useCallback(() => {
    return {
      name: activeModal?.fundraiser?.fundRaisingName,
      address: activeModal?.fundraiser?.address,
      city: activeModal?.fundraiser?.city,
      state: activeModal?.fundraiser?.state,
      zipCode: activeModal?.fundraiser?.zip,
      startDate: activeModal?.fundraiser?.fundRaisingStartTime
        ? new Date(parseInt(activeModal?.fundraiser?.fundRaisingStartTime))
            .toISOString()
            .split("T")[0]
        : "",
      endDate: Math.floor(
        Math.abs(
          activeModal?.fundraiser?.fundRaisingEndTime -
            activeModal?.fundraiser?.fundRaisingStartTime
        ) /
          (1000 * 60 * 60 * 24 * 7)
      ),
      routingNumber: activeModal?.fundraiser?.fundRaisingRoutingNumber,
      accountNumber: activeModal?.fundraiser?.fundRaisingAccountNumber,
      verifyAccountNumber: activeModal?.fundraiser?.fundRaisingAccountNumber,
      taxId: activeModal?.fundraiser?.fundRaisingTaxId,
      checks: {
        isAuthorizedAdmin: false,
        isPrivacyPolicy: false,
        isTerms: false,
      },
    };
  }, [activeModal]);

  const handleGetAllFundraisers = async () => {
    try {
      setLoader(true);
      let response = await getAllFundraisers();

      //console.log(" Fundraiser Response ...........", response);
      let tempArray =
        response.length === 0
          ? []
          : response.map((data) => {
              return {
                name: data.fundRaisingName,
                totalFunds: data.totalFunding,
                _id: data._id,
                ...data,
              };
            });
      setFundraisers(tempArray);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      let errorMessage =
        error.response?.data?.message || "Fundraisers could not be loaded";
      Toaster("error", errorMessage);
    }
  };

  const handleFundraiserSubmit = async (values) => {
    try {
      let body = {
        fundRaisingTaxId: values?.taxId,
        fundRaisingRoutingNumber: values?.routingNumber,
        fundRaisingAccountNumber: values?.accountNumber,
        confirmFundRaisingAccountNumber: values?.verifyAccountNumber,
        fundRaisingName: values?.name,
        fundRaisingPara1: values?.para1,
        fundRaisingPara2: values?.para2,
        fundRaisingGoal: values?.goal,
        city: values?.city,
        state: values?.state,
        zip: values?.zipCode,
        address: values?.address,
        fundRaisingStartTime: dateToLocalTimestamp(values?.startDate),
        fundRaisingEndTime: dateToLocalTimestamp(values?.endDate),
        image: values?.image,
      };

      let formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }

      if (activeModal?.fundraiser) {
        formData.append("fundRaisingId", activeModal?.fundraiser._id);
        await updateFundraiser(formData);
      } else {
        await createFundraiser(formData);
      }

      await handleGetAllFundraisers();
      Toaster(
        "success",
        activeModal?.fundraiser
          ? "Fundraiser has been updated successfully"
          : "Fundraiser has been created successfully"
      );
      return true;
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Fundraiser could not be created";
      Toaster("error", errorMessage);
      throw new Error(errorMessage);
    }
  };

  const handleDelete = async (value) => {
    setActiveModal({
      type: FUNDRAISER_MODALS.DELETE_FUNDRAISER,
      fundraiser: value,
    });
  };

  const handleFundraiserDelete = async () => {
    try {
      if (activeModal?.fundraiser) {
        setLoader(true);
        await deleteFundraiser(activeModal.fundraiser._id);
        await handleGetAllFundraisers();
        setLoader(false);
        setActiveModal(null);
        Toaster("success", "Fundraiser Deleted Successfully");
      }
    } catch (error) {
      setLoader(false);
      let errorMessage =
        error.response?.data?.message || "Fundraiser could not be deleted";
      Toaster("error", errorMessage);
      throw new Error(errorMessage);
    }
  };

  useEffect(() => {
    handleGetAllFundraisers();
  }, []);

  return (
    <div className="admin-fundraiser-wrapper">
      {auth && auth?.role !== "secondaryAdmin" && (
        <div className="admin-sub-title">Fundraisers</div>
      )}
      {auth && auth?.role === "secondaryAdmin" ? (
        <div className="admin-fundraiser-list-wrapper">
          <div className="admin-invite-button-wrapper">
            <CustomButton
              title={"Start New Fundraiser"}
              onClick={handleOpenFundraiserModal}
              className={"fundraiser-button"}
              //disabled={Object.keys(errors).length > 0}
            />
          </div>
        </div>
      ) : (
        <div className="admin-fundraiser-list-wrapper">
          <CustomTable
            columns={fundraiserColumn}
            loader={loader}
            rows={fundraisers}
            maxHeight={300}
            isActions
            onEdit={handleEdit}
            onDelete={handleDelete}
            isSelection
            onSelect={selectFundraiser}
          />
          <div className="admin-invite-button-wrapper">
            <CustomButton
              title={"Open Fundraiser Page"}
              onClick={() =>
                navigate(
                  "/admin/dashboard/" + fundraisers[selectedFundraiser]._id
                )
              }
              className={"fundraiser-button"}
              disabled={selectedFundraiser === null}
            />
            <CustomButton
              title={"Start New Fundraiser"}
              onClick={handleOpenFundraiserModal}
              className={"fundraiser-button"}
            />
          </div>
        </div>
      )}
      <NewFundraiserModalV2
        open={activeModal?.type === FUNDRAISER_MODALS.NEW_FUNDRAISER}
        onClose={onModalClose}
      />
      <FundraiserInfoModal
        fundraiserData={getFundraisingInfo()}
        title={"Update Fundraiser"}
        loader={loader}
        updateFundraiserData={updateFundraiserInfo}
        open={activeModal?.type === FUNDRAISER_MODALS.UPDATE_FUNDRAISER}
        onClose={onModalClose}
      />
      {/* <NewFundraiserModal
        selectedData={selectedFundraiserForAction}
        open={openNewFundraiser}
        onClose={handleCloseFundraiserModal}
        title={fundraiserTitle}
        onSubmit={handleFundraiserSubmit}
      /> */}
      <ConfirmationModal
        title={"Confirm Delete Fundraiser"}
        prompt={`Are you sure you want to delete this fundraiser${
          activeModal?.fundraiser?.fundRaisingName
            ? " (" + activeModal?.fundraiser?.fundRaisingName + ")"
            : ""
        }? This action can not be undone!`}
        open={activeModal?.type === FUNDRAISER_MODALS.DELETE_FUNDRAISER}
        onClose={onModalClose}
        onConfirmation={handleFundraiserDelete}
        warningOnConfirm
        loader={loader}
      />
    </div>
  );
};

export default Fundraiser;
